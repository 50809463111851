import { IConfigurationSend, Task } from "../components/Home/ISettings";
import { useAppSelector } from "../redux/store";
import { IStore } from "../redux/store/IStore";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

const usePipeline = () => {
    const {
        enqueueSnackbar
    } = useSnackbar();

    const activeConfiguration = useAppSelector(store => store.app.activeConfiguration)
    const activePunctuator = useAppSelector(store => store.app.activePunctuator)
    const activeInverseTextNormalizator = useAppSelector(store => store.app.activeInverseTextNormalizator)
    const activeTrueCaser = useAppSelector(store => store.app.activeTrueCaser)
    const speakerSettingsModal = useAppSelector(store => store.app.speakerSettingsModal)

    const buildPipeline = useCallback((): IConfigurationSend | null => {

        console.log(activeConfiguration)
        if (!activeConfiguration.model) {
            enqueueSnackbar(`Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.`, {
                variant: 'error',
                autoHideDuration: 4000,
            });
            return null;
        }

        const liveConfig: IConfigurationSend = []

        let asrParameters = {}
        if (activeConfiguration.model.enableUnks && activeConfiguration.model.enableUnks.value) asrParameters["enableUnks"] = true
        if (activeConfiguration.model.enableInterims && activeConfiguration.model.enableInterims.value) asrParameters["enableInterims"] = true
        if (activeConfiguration.model.enableSd && activeConfiguration.model.enableSd.value) {
            asrParameters["enableSd"] = true
            if (speakerSettingsModal.numberOfSpeakers) {
                asrParameters["sdMinSpeakers"] = speakerSettingsModal.numberOfSpeakers
                asrParameters["sdMaxSpeakers"] = speakerSettingsModal.numberOfSpeakers
            } else {
                if (speakerSettingsModal.minNumberOfSpeakers) asrParameters["sdMinSpeakers"] = speakerSettingsModal.minNumberOfSpeakers
                if (speakerSettingsModal.maxNumberOfSpeakers) asrParameters["sdMaxSpeakers"] = speakerSettingsModal.maxNumberOfSpeakers
            }
            
        }
        liveConfig.push({
            task: Task.asr,
            config: {
                tag: `${activeConfiguration.serviceProvider}:${activeConfiguration.language}:${activeConfiguration.domain}:${activeConfiguration.model.name}`,
                parameters: asrParameters,
            }
        })

        if (activePunctuator.toggled && !(activePunctuator.language === null || activePunctuator.domain === null || activePunctuator.model === null)) {
            //TODO: Add service provider to punctuator
            let pcParameters = {}
            if (activePunctuator.model.enableSplitToSentences && activePunctuator.model.enableSplitToSentences.value) {
                pcParameters["enableSplitToSentences"] = true
            }
            liveConfig.push({
                task: Task.pc,
                config: {
                    tag: `${activePunctuator.model.serviceProvider}:${activePunctuator.language}:${activePunctuator.domain}:${activePunctuator.model.name}`,
                    parameters: pcParameters
                }
            })
        }

        if (activeInverseTextNormalizator.toggled && !(activeInverseTextNormalizator.language === null || activeInverseTextNormalizator.domain === null || activeInverseTextNormalizator.model === null)) {
            //TODO: Add service provider to normalizer
            liveConfig.push({
                task: Task.itn,
                config: {
                    tag: `${activeInverseTextNormalizator.model.serviceProvider}:${activeInverseTextNormalizator.language}:${activeInverseTextNormalizator.domain}:${activeInverseTextNormalizator.model.name}`,
                    parameters: {},
                }
            })
        }

        if (activeTrueCaser.toggled && !(activeTrueCaser.language === null || activeTrueCaser.domain === null || activeTrueCaser.model === null)) {
            //TODO: Add service provider to trueCaser
            liveConfig.push({
                task: Task.tc,
                config: {
                    tag: `${activeTrueCaser.model.serviceProvider}:${activeTrueCaser.language}:${activeTrueCaser.domain}:${activeTrueCaser.model.name}`,
                    parameters: {},
                }
            })
        }

        return liveConfig;
    }, [
        activeConfiguration,
        activePunctuator,
        activeInverseTextNormalizator,
        activeTrueCaser,
        speakerSettingsModal
    ])

    return {
        buildPipeline
    }
}

export default usePipeline;